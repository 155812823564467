export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_VERIFY = 'AUTH_VERIFY';
export const VERIFY_ID = 'VERIFY_ID';
export const PASSWORD_RECOVERY = 'PASSWORD_RECOVERY';
export const USER_DETAILS = 'USER_DETAILS';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_LATEST_PRODUCTS = 'SET_LATEST_PRODUCTS';
export const SET_CONFIGS = 'SET_CONFIGS';
export const SET_ONBOARDING_CONFIGS = 'SET_ONBOARDING_CONFIGS';
export const ACCOUNTS_CONFIGS = 'ACCOUNTS_CONFIGS';
export const LISTINGS_CONFIGS = 'LISTINGS_CONFIGS';
export const PAYMENTS_CONFIGS = 'PAYMENTS_CONFIGS';

export const INIT_TENANT_CONFIG = 'INIT_TENANT_CONFIG';
export const INIT_COUNTRIES = 'INIT_COUNTRIES';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const FETCH_COUNTRIES_FAILED = 'FETCH_COUNTRIES_FAILED';

export const INIT_HOME_COLLECTIONS = 'INIT_HOME_COLLECTIONS';
export const SET_HOME_COLLECTIONS = 'SET_HOME_COLLECTIONS';
export const SET_PROMO_BANNERS = 'SET_PROMO_BANNERS';
export const SET_STORES_TO_FOLLOW = 'SET_STORES_TO_FOLLOW';
export const FETCH_COLLECTIONS_FAILED = 'FETCH_COLLECTIONS_FAILED';

export const INIT_PRODUCT_DETAILS = 'INIT_PRODUCT_DETAILS';
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const FETCH_PRODUCT_DETAILS_FAILED = 'FETCH_PRODUCT_DETAILS_FAILED';

export const INIT_STORE_DETAILS = 'INIT_STORE_DETAILS';
export const SET_STORE_DETAILS = 'SET_STORE_DETAILS';
export const FETCH_STORE_DETAILS_FAILED = 'FETCH_STORE_DETAILS_FAILED';
export const SET_ALL_STORES = 'SET_ALL_STORES';
export const START_SEARCHING = 'START_SEARCHING';
export const ADDRESS_SEARCH = 'ADDRESS_SEARCH';
export const SET_ACCOUNTS_CATEGORIES = 'SET_ACCOUNTS_CATEGORIES';
export const SET_IMAGE_FILE = 'SET_IMAGE_FILE';
export const SET_ATTRIBUTE ='SET_ATTRIBUTE';

export const INIT_LISTING = 'INIT_LISTING';
export const SET_LISTING = 'SET_LISTING';
export const FETCH_LISTING_FAILED = 'FETCH_LISTING_FAILED';

export const INIT_STORE_LISTS = 'INIT_STORE_LISTS';
export const SET_STORE_LISTS = 'SET_STORE_LISTS';
export const FETCH_STORE_LISTS_FAILED = 'FETCH_STORE_LISTS_FAILED';

export const SET_CATEGORY_LISTS = 'SET_CATEGORY_LISTS';
export const INIT_CATEGORY_LISTS = 'INIT_CATEGORY_LISTS';
export const FETCH_CATEGORY_LISTS_FAILED = 'FETCH_CATEGORY_LISTS_FAILED';

export const INIT_CREATE_STORE = 'INIT_CREATE_STORE';
export const CREATE_STORE_FAILED = 'CREATE_STORE_FAILED';
export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
export const EDIT_STORE = 'EDIT_STORE'

export const SUCCESS_TENANT_CONFIG = 'SUCCESS_TENANT_CONFIG';

export const FAILED_TENANT_CONFIG = 'FAILED_TENANT_CONFIG';

export const INIT_GROUP_DETAILS = 'INIT_GROUP_DETAILS';
export const SET_GROUP_DETAILS = 'SET_GROUP_DETAILS';
export const FETCH_GROUP_DETAILS_FAILED = 'FETCH_GROUP_DETAILS_FAILED';
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const INIT_CREATE_GROUP = 'INIT_CREATE_GROUP';

export const SET_SUPPLIER_LISTS = 'SET_SUPPLIER_LISTS';
export const INIT_SUPPLIER_LISTS = 'INIT_SUPPLIER_LISTS';
export const FETCH_SUPPLIER_LISTS_FAILED = 'FETCH_SUPPLIER_LISTS_FAILED';

export const START_PRODUCT_LIKE_DISLIKE = 'START_PRODUCT_LIKE_DISLIKE';
export const SET_PRODUCT_LIKE_DISLIKE = 'SET_PRODUCT_LIKE_DISLIKE';
export const FAILED_PRODUCT_LIKE_DISLIKE = 'FAILED_PRODUCT_LIKE_DISLIKE';

export const POST_STORE_FOLLOW_REQUEST = 'POST_STORE_FOLLOW_REQUEST';
export const POST_STORE_FOLLOW_SUCCESS = 'POST_STORE_FOLLOW_SUCCESS';
export const POST_STORE_FOLLOW_FAILED = 'POST_STORE_FOLLOW_FAILED';


export const CART_LOADING = 'CART_LOADING';
export const ADD_TO_CART = 'ADD_TO_CART';
export const GET_CART = 'GET_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const DELETE_ALL_CART_DETAILS = 'DELETE_ALL_CART_DETAILS';

export const GET_WISH_LIST = 'GET_WISH_LIST';


export const INIT_SEARCH = 'INIT_SEARCH';
export const SET_SEARCH = 'SET_SEARCH';

export const ERROR_MESSAGE = 'ERROR_MESSAGE';

export const START_LOADING = 'START_LOADING';

export const INIT_CURRENCIES = 'INIT_CURRENCIES';

export const ADD_PRODUCT = 'ADD_PRODUCT';

export const SEO_CONFIGS = 'SEO_CONFIG'

export const START_PAYMENT_LOADING = 'START_PAYMENT_LOADING';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const GET_ADDRESS = 'GET_ADDRESS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const CHECKOUT = 'CHECKOUT';
export const EPHEMERAL_KEY = 'EPHEMERAL_KEY';
export const PAYMENT_INTENT = 'PAYMENT_INTENT';
export const STRIPE_CONNECT = 'STRIPE_CONNECT';
export const CREATE_EXPRESS_LOGIN = 'CREATE_EXPRESS_LOGIN';
export const CREATE_STRIPE_ACCOUNT = 'CREATE_STRIPE_ACCOUNT';



export const ORDER_LOADING = 'ORDER_LOADING';
export const GET_ORDERS = 'GET_ORDERS';
export const ORDER_DETAILS = 'ORDER_DETAILS';
export const ORDER_STATUS_UPDATE = 'ORDER_STATUS_UPDATE';
export const ORDER_DETAILS_UPDATE = 'ORDER_DETAILS_UPDATE';